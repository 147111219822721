import React from "react";

const Container = ({ children, title }) => {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-center font-semibold text-xl">{title}</h2>
        <hr class="mb-12 mt-2 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
        {children}
      </div>
    </div>
  );
};

export default Container;
