import Header from "./Components/Header";
import Productos from "./Components/Productos";
import Paquetes from "./Components/Paquetes";

import menu from "./items/menu.json";
import { paquetes } from "./lib/constants";

const App = () => {
  return (
    <main>
      <Header />
      <Productos currentMenu={menu} />
      <Paquetes paquetes={paquetes} />
    </main>
  );
};

export default App;
